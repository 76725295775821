import React from "react";

const Share = () => (
  <svg
    width="34"
    height="40"
    viewBox="0 0 34 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.80005 20V35.2C1.80005 36.2078 2.2004 37.1744 2.91304 37.887C3.62568 38.5996 4.59223 39 5.60005 39H28.4C29.4079 39 30.3744 38.5996 31.0871 37.887C31.7997 37.1744 32.2 36.2078 32.2 35.2V20"
      stroke="#A8B3CE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.6001 8.6L17.0001 1L9.40015 8.6"
      stroke="#A8B3CE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17 1V25.7"
      stroke="#A8B3CE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default Share;
