import React from "react";

const Ellipse = () => (
  <svg
    width="4"
    height="4"
    viewBox="0 0 4 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="1.9209" cy="2.00001" r="1.48682" fill="#D9D9D9" />
  </svg>
);

export default Ellipse;
