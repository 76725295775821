import React from "react";

const Heart = () => (
  <svg
    width="37"
    height="32"
    viewBox="0 0 37 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.8982 4.2624L20.8977 4.26286L19.5126 5.66753L18.8006 6.38961L18.0885 5.66752L16.7034 4.26286L16.7022 4.26169C13.4964 0.999857 8.34713 0.526944 4.93818 3.3857C0.975037 6.71492 0.764836 12.6945 4.31205 16.3039L20.8982 4.2624ZM20.8982 4.2624C24.1116 0.999401 29.254 0.527307 32.6626 3.38539C36.6261 6.7146 36.8363 12.6943 33.2823 16.3037L33.2821 16.3039L19.677 30.1289C19.677 30.129 19.6769 30.1291 19.6768 30.1291C19.1897 30.6236 18.4044 30.6236 17.9173 30.1291C17.9172 30.1291 17.9171 30.129 17.9171 30.1289L4.31247 16.3044L20.8982 4.2624Z"
      stroke="#A8B3CE"
      stroke-width="2"
    />
  </svg>
);

export default Heart;
