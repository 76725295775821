import { css } from "styled-components";

import PoppinsExtraLightTtf from "@fonts/Poppins/Poppins-ExtraLight.ttf";
import PoppinsLightTtf from "@fonts/Poppins/Poppins-Light.ttf";
import PoppinsRegularTtf from "@fonts/Poppins/Poppins-Regular.ttf";
import PoppinsMediumTtf from "@fonts/Poppins/Poppins-Medium.ttf";
import PoppinsSemiboldTtf from "@fonts/Poppins/Poppins-SemiBold.ttf";

import PoppinsExtraLightItalicTtf from "@fonts/Poppins/Poppins-ExtraLightItalic.ttf";
import PoppinsLightItalicTtf from "@fonts/Poppins/Poppins-LightItalic.ttf";
import PoppinsRegularItalicTtf from "@fonts/Poppins/Poppins-Italic.ttf";
import PoppinsMediumItalicTtf from "@fonts/Poppins/Poppins-MediumItalic.ttf";
import PoppinsSemiboldItalicTtf from "@fonts/Poppins/Poppins-SemiBoldItalic.ttf";

import MontserratExtraLightTtf from "@fonts/Montserrat/Montserrat-ExtraLight.ttf";
import MontserratRegularTtf from "@fonts/Montserrat/Montserrat-Regular.ttf";
import MontserratSemiboldTtf from "@fonts/Montserrat/Montserrat-SemiBold.ttf";

import MontserratExtraLightItalicTtf from "@fonts/Montserrat/Montserrat-ExtraLightItalic.ttf";
import MontserratRegularItalicTtf from "@fonts/Montserrat/Montserrat-Italic.ttf";
import MontserratSemiboldItalicTtf from "@fonts/Montserrat/Montserrat-SemiBoldItalic.ttf";

const poppinsNormalWeights = {
  200: [PoppinsExtraLightTtf],
  300: [PoppinsLightTtf],
  400: [PoppinsRegularTtf],
  500: [PoppinsMediumTtf],
  600: [PoppinsSemiboldTtf],
};

const poppinsItalicWeights = {
  200: [PoppinsExtraLightItalicTtf],
  300: [PoppinsLightItalicTtf],
  400: [PoppinsRegularItalicTtf],
  500: [PoppinsMediumItalicTtf],
  600: [PoppinsSemiboldItalicTtf],
};

const montserratNormalWeights = {
  200: [MontserratExtraLightTtf],
  400: [MontserratRegularTtf],
  600: [MontserratSemiboldTtf],
};

const montserratItalicWeights = {
  200: [MontserratExtraLightItalicTtf],
  400: [MontserratRegularItalicTtf],
  600: [MontserratSemiboldItalicTtf],
};

const poppins = {
  name: "Poppins",
  normal: poppinsNormalWeights,
  italic: poppinsItalicWeights,
};

const montserrat = {
  name: "Montserrat",
  normal: montserratNormalWeights,
  italic: montserratItalicWeights,
};

const createFontFaces = (family, style = "normal") => {
  let styles = "";

  for (const [weight, formats] of Object.entries(family[style])) {
    const tff = formats[0];

    styles += `
      @font-face {
        font-family: '${family.name}';
        src: url(${tff}) format('tff');
        font-weight: ${weight};
        font-style: ${style};
        font-display: auto;
      }
    `;
  }

  return styles;
};

const poppinsNormal = createFontFaces(poppins);
const poppinsItalic = createFontFaces(poppins, "italic");

const montserratNormal = createFontFaces(montserrat);
const montserratItalic = createFontFaces(montserrat, "italic");

const Fonts = css`
  ${poppinsNormal + poppinsItalic + montserratNormal + montserratItalic}
`;

export default Fonts;
