import React from "react";

const Listen = () => (
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.8 39C31.2935 39 39.8 30.4934 39.8 20C39.8 9.50659 31.2935 1 20.8 1C10.3066 1 1.80005 9.50659 1.80005 20C1.80005 30.4934 10.3066 39 20.8 39Z"
      stroke="#A8B3CE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17 12.4L28.4 20L17 27.6V12.4Z"
      stroke="#A8B3CE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default Listen;
