import React, { useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styled from "styled-components";
import { navDelay, loaderDelay } from "@utils";
import { usePrefersReducedMotion } from "@hooks";
import Typer from "../Typer";
// import { email } from '@config';
import { graphql, useStaticQuery } from "gatsby";

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  padding: 0;
  position: relative;

  @media (max-width: 480px) and (min-height: 700px) {
    padding-bottom: 10vh;
  }

  h1 {
    margin: 0 0 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 300;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h3 {
    margin-top: 10px;
    color: var(--slate);
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 540px;
  }

  .five-div {
    margin-top: 40px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const result = useStaticQuery(
    graphql`
      query {
        allContentfulLandingPage {
          edges {
            node {
              name
              subtitle
              description {
                childMarkdownRemark {
                  excerpt
                }
              }
              titles
              aboutMe {
                aboutMe
              }
              introString
            }
          }
        }
      }
    `
  );

  const data = result.allContentfulLandingPage.edges[0].node;

  const one = <h1>{data.introString}</h1>;
  const two = <h2 className="big-heading">{data.name}</h2>;
  const three = <h3 className="big-heading">{data.subtitle}</h3>;
  const four = (
    <p style={{ paddingTop: "10px" }} className="sub-text">
      {}
    </p>
  );
  const five = (
    <div className="five-div">
      <Typer className="typer-messages" messages={data.titles} />
    </div>
  );

  const items = [one, two, three, four, five];

  return (
    <StyledHeroSection>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>
  );
};

export default Hero;
