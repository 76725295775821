import React from "react";

const Comment = () => (
  <svg
    width="37"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.0593 27.376L31.9794 27.0078L32.2271 26.7239C34.6201 23.9813 36.05 20.5053 36.05 16.7143C36.05 7.94687 28.3814 0.75 18.8 0.75C9.21873 0.75 1.55005 7.94687 1.55005 16.7143C1.55005 25.4817 9.21873 32.6786 18.8 32.6786C21.3934 32.6786 23.8453 32.1307 26.0554 31.1806L26.4993 30.9898L26.8566 31.3151C28.4949 32.8069 31.7063 35.1447 35.9505 35.2466C35.6883 34.9112 35.0708 34.0855 34.3923 32.956C33.5226 31.5083 32.5232 29.5138 32.0593 27.376ZM36.0703 35.3965C36.069 35.395 36.0677 35.3933 36.0662 35.3916C36.0675 35.3932 36.0688 35.3948 36.0702 35.3965L36.0703 35.3965Z"
      stroke="#A8B3CE"
      stroke-width="1.5"
    />
  </svg>
);

export default Comment;
